const serverUrl = 'https://navigator-data.agentbase.ru';
const feedUrl = 'https://navigator-data.agentbase.ru/xml/';

export default {
    serverUrl,
    photoUploadUrl: serverUrl + '/uploadPhotosHandler',
    feedUrl,
    pushNotifications: {
        operator: '',
        appId: '85ed58cc-8b98-4b8a-beb3-ddd7f99995f5'
    },
    crmOutgoingCalls: false,
    callsServerUrl: 'https://data.agentbase.ru:2935/',
    whatsappServerUrl: ''
};
